
#boxshadow {
    border: 0px solid;
    padding: 10px;
    /* box-shadow: 0 .25rem .75rem  rgba(176, 196, 222, .5); */
    box-shadow: 0 .25rem .75rem  #3e739d;
}
.gpm-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
    grid-template-rows: 30vh 60vh;
    grid-gap: 0vw 0;
}

.gpm-container > div {
    text-align: center;
    padding: 1vw 1vh;
}
.gpm-flex-container-row {
    display: flex;
    flex-direction: row;
    text-align: center;
}
.gpm-flex-container-column {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.gpm-flex-item-left-3 {
    padding: 2rem;
    flex: 33%;
}
.gpm-flex-item-middle-3 {
    padding: 2rem;
    flex: 33%;
}   
.gpm-flex-item-right-3 {
    padding: 2rem;
    flex: 33%;
}

.gpm-flex-item-left-2 {
    padding: 2rem;
    flex: 67%;
}
.gpm-flex-item-right-2 {
    padding: 2rem;
    flex: 33%;
}

.console
{
    grid-column: 1 / 12;
    grid-row: 2;
    margin-left: .1rem;
    margin-right: .1rem;
}
.ribbon
{
    grid-column: 1 / 12;
    grid-row: 1;
    margin-left: .1rem;
    margin-right: .1rem;}