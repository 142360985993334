#boxshadow {
    border: 0px solid;
    padding: 10px;
    /* box-shadow: 0 .25rem .75rem  rgba(176, 196, 222, .5); */
    box-shadow: 0 .25rem .75rem  #3e739d;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
    grid-template-rows: 55vh 35vh;
    grid-gap: 0vw 0;
    /* background-color: white; */
}

.grid-container > div {
    /* background-color: white; */
    text-align: center;
    padding: .35rem .35rem;
}

.jobs
{
    grid-column: 1 / 5;
    grid-row: 1;
}
.healthstatus
{
    grid-column: 5 / 12;
    grid-row: 1;

}
.events
{
    grid-column: 1 / 12;
    grid-row: 2;
}
.temp1
{
    grid-column: 6 / 8;
    grid-row: 2;
}
.temp2
{
    grid-column: 8 / 10;
    grid-row: 2;
}
.temp3
{
    grid-column: 10 / 12;
    grid-row: 2;
}
.search-container {
    float: top;
  }
  
  
.search-container button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
}
.search-container button:hover {
    background: #ccc;
}

.input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 2px;
}
.input-field {
    width: 100%;
    padding: 10px;
    outline: none;
} 
  