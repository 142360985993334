@import url("https://www.w3schools.com/w3css/4/w3.css");


.flex-container-row {
    display: flex;
    flex-direction: row;
    text-align: center;
}
.flex-container-column {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.flex-item-left-3 {
    padding: 2px;
    flex: 33%;
}
.flex-item-middle-3 {
    padding: 2px;
    flex: 33%;
}   
.flex-item-right-3 {
    padding: 2px;
    flex: 33%;
}

.flex-item-left-2 {
    padding: 2px;
    flex: 67%;
}
.flex-item-right-2 {
    padding: 2px;
    flex: 33%;
}
.tooltip {
    position: relative;
    display: inline-block;
    /*border-bottom: 1px dotted black;*/
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -1.5rem;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .icon-div {
    vertical-align: middle;
    text-align: left;
  }  
  .label-div {
    vertical-align: middle;
    text-align: left;
     
  } 
  .text-div {
    vertical-align: middle;
    text-align: right;
    padding-left: 1rem;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;

  } 

  .job-detail-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  
  }
  .job-detail-container .label-div {
    flex: 15%;
    vertical-align: middle;
    text-align: left;
     
  } 
  .job-detail-container .text-div {
    flex: 85%;
    vertical-align: middle;
    text-align: left;
    padding-left: 1rem;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;

  } 