/* sidebar Nav */

header {
	height: 5rem;
	background-color: #3e739d;
	border-bottom: 1px solid #494949;
	display: flex;
	align-items: center;
	justify-content: center;
}
header > h3 {
	width: 100%;
	text-align: center;
	color: white;
	top: 2rem;
}
header > .top {
	position: absolute;
	left: 1rem;
	top: 2rem;
}
header > .top a.menu_icon i {
	color: #000;
	font-size: 5rem;
	padding-top: 5px;
	transition: .2s ease;
}
header > .top a.menu_icon:hover i {
	color: #494949;;
}
nav.menu {
	width: 300px;
	/* min-height: calc(100vh - 121px); */
	background-color: #3e739d;
    height: 90%;
	position: absolute;
    top: 5rem;
	left: -300px;
	transition: .3s all;
    z-index: 100;
}
nav.menu > a {
    display: block;
    padding: 5px;
    margin: 15px 0 0px 20px;
    color: #fff; 
    text-transform: uppercase;
    font-weight: bold;
}

.menu_show {
	left: 0!important;
    width: 100%;
}

@media screen and (max-width: 425px) {
	header h3 {
		font-size: 16px;
	}
}
@media screen and (max-width: 360px) {
	nav.menu {
		width: 100%;
		left: -100%; 
	}
}
